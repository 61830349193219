<template>
  <div class="vertical-timeline-block">
    <div class="vertical-timeline-icon navy-bg">
      <i :class="['fa', data.icon]"></i>
    </div>

    <div class="vertical-timeline-content">
      <h2>{{ data.title }}</h2>
      <p>
        {{ data.description }}
      </p>
      <!-- <a href="#" class="btn btn-xs btn-primary"> More info</a> -->
      <span class="vertical-date">
        {{ data.info.text }} <small>{{ data.info.highlight }}</small>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: function() {
        return {
          icon: "",
          title: "",
          description: "",
          link: { url: "", text: "" },
          info: { text: "", highlight: "" },
        };
      },
    },
  },
};
</script>

<style></style>
