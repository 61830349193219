<template>
  <div class="navbar-wrapper">
    <nav class="navbar navbar-default navbar-fixed-top" role="navigation">
      <div class="container">
        <div class="navbar-header page-scroll">
          <button
            type="button"
            class="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#navbar"
            aria-expanded="false"
            aria-controls="navbar"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" href="/">ACCYON</a>
        </div>
        <div id="navbar" class="navbar-collapse collapse">
          <ul class="nav navbar-nav navbar-right">
            <li><a class="page-scroll" href="#page-top">Inicio</a></li>
            <li><a class="page-scroll" href="#features">Servicios</a></li>
            <!-- <li><a class="page-scroll" href="#team">Equipo</a></li> -->
            <li>
              <a class="page-scroll" href="#testimonials">Testimonios</a>
            </li>
            <li>
              <a class="page-scroll" href="#jobs">Empleos</a>
            </li>
            <li><a class="page-scroll" href="#contact">Contactanos</a></li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  
};
</script>
