<template>
  <div :class="['item', data.active ? 'active' : '']">
    <div class="container">
      <div class="carousel-caption blank">
        <h1>
          <span v-for="(line, index) in title_lines" :key="index">
            {{ line }}<br
          /></span>
        </h1>
        <p>{{ data.description }}</p>
        <p>
          <a
            v-if="data.action.text != ''"
            class="btn btn-lg btn-primary"
            :href="data.action.path"
            role="button"
            >{{ data.action.text }}</a
          >
          <a
            v-if="data.link.text != ''"
            class="caption-link"
            :href="data.link.path"
            role="button"
            >{{ data.link.text }}</a
          >
        </p>
      </div>
      <div v-if="data.img" class="carousel-image wow zoomIn">
        <img :src="`img/${data.img}`" :alt="data.imgalt" />
      </div>
    </div>
    <div :class="['header-back', data.background]"></div>
  </div>
</template>

<script>
import { chunkSentence } from "../utils/string_fns";
export default {
  props: {
    data: {
      type: Object,
      default: function() {
        return {
          active: false,
          title: "",
          description: "",
          action: { path: "#", text: "" },
          link: { path: "#", text: "" },
          background: "",
          img: "",
          imgalt: "",
        };
      },
    },
  },
  computed: {
    title_lines() {
      return chunkSentence(this.data.title || "", 4);
    },
  },
};
</script>

<style></style>
