<template>
  <div>
    <NavbarLayout />
    <HeaderLayout />
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view>
    <Footer />
  </div>
</template>

<script>
import HeaderLayout from "../sections/HeaderLayout.vue";
import NavbarLayout from "../sections/NavbarLayout.vue";
import Footer from "../sections/Footer.vue";
export default {
  components: {
    HeaderLayout,
    NavbarLayout,
    Footer,
  },
};
</script>
