<template>
  <div id="inSlider" class="carousel carousel-fade" data-ride="carousel">
    <ol class="carousel-indicators">
      <BannerLink
        v-for="(slide, index) in slides"
        :key="index"
        :to="index"
        :active="slide.active"
      />
    </ol>
    <div class="carousel-inner" role="listbox">
      <BannerSlide
        v-for="(slide, index) in slides"
        :key="index"
        :data="slide"
      />
    </div>
    <a
      class="left carousel-control"
      href="#inSlider"
      role="button"
      data-slide="prev"
    >
      <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
      <span class="sr-only">Anterior</span>
    </a>
    <a
      class="right carousel-control"
      href="#inSlider"
      role="button"
      data-slide="next"
    >
      <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
      <span class="sr-only">Siguiente</span>
    </a>
  </div>
</template>

<script>
import BannerSlide from "../components/BannerSlide.vue";
import BannerLink from "../components/BannerLink.vue";
export default {
  name: "Banner",
  data() {
    return {
      slides: [
        {
          title: "Únete a nuestra fuerza de trabajo.",
          description: "Somos tu mejor opción.",
          active: true,
          action: { path: "#/app/register", text: "Regístrate" },
          link: { path: "#", text: "" },
          background: "one",
        },
        {
          title: "Tu hoja de vida, es la llave",
          description: "Para una gran oportunidad. llena nuestro formulario de registro",
          active: false,
          action: { path: "#/app/register", text: "Aquí" },
          link: { path: "#", text: "" },
          background: "two",
          img: "laptop.png",
          imgalt: "laptop",
        },
      ],
    };
  },
  components: {
    BannerSlide,
    BannerLink,
  },
};
</script>
