// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// import firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/firestore'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import 'firebase/auth';  // If using Firebase storage

// const firestoreSettings = {
//     host: 'emulator.firebase.local:8081',
//     ssl: false,
//     timestampsInSnapshots: true
// };

const firebaseConfig = {
    apiKey: "AIzaSyBMUkrZQD82CLZpShc-fz_WgEwdHMbnef4",
    authDomain: "accyon.firebaseapp.com",
    databaseURL: "https://accyon.firebaseio.com",
    projectId: "accyon",
    storageBucket: "accyon.appspot.com",
    messagingSenderId: "613826633795",
    appId: "1:613826633795:web:022874e84387630f0f74f6",
    measurementId: "G-ER8E1B4QE8"
};

firebase.initializeApp(firebaseConfig);

// firebase.firestore().settings(firestoreSettings);

export default firebase;