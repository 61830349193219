<template>
  <div class="col-lg-4">
    <div class="bubble">"{{ data.comments }}"</div>
    <div class="comments-avatar">
      <!-- <a href="#" class="pull-left"> -->
      <span class="pull-left">
        <img alt="image" :src="`./img/testimonials/${data.img}`" />
      </span>
      <!-- </a> -->
      <div class="media-body">
        <div class="commens-name">
          {{ data.name }}
        </div>
        <small class="text-muted">{{ data.company }}</small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: function() {
        return {
          comments: "",
          img: "default.png",
          name: "",
          company: "",
        };
      },
    },
  },
};
</script>

<style></style>
