<template>
  <section id="headerlayout" class="">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="logo" :style="backgroundImage"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeaderLayout",
  computed: {
    backgroundImage: function() {
      return {
        backgroundImage: `url("./img/logo.png")`,
      };
    },
  },
};
</script>
<style scoped>
.logo {
  margin-top: 60px;
  min-height: 128px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
