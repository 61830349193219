<template>
  <div>
    <NavbarPage />

    <Banner />

    <Us />

    <Services />

    <!-- <Team /> -->

    <Expertis />

    <Process />

    <Caracteristics />

    <Testimonials />

    <Jobs />

    <Contact />
  </div>
</template>
<script>
import NavbarPage from "../sections/NavbarPage.vue";
import Banner from "../sections/Banner.vue";
import Process from "../sections/Process.vue";
import Caracteristics from "../sections/Caracteristics.vue";
import Testimonials from "../sections/Testimonials.vue";
import Expertis from "../sections/Expertis.vue";
import Jobs from "../sections/Jobs.vue";
import Contact from "../sections/Contact.vue";
import Services from "../sections/Services.vue";
// import Team from "../sections/Team.vue";
import Us from "../sections/Us.vue";

export default {
  components: {
    Banner,
    Caracteristics,
    Contact,
    NavbarPage,
    Services,
    Process,
    Expertis,
    Jobs,
    // Team,
    Testimonials,
    Us,
  },
};
</script>
