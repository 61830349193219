<template>
  <section id="contact" class="gray-section contact">
    <div class="container">
      <div class="row m-b-lg">
        <div class="col-lg-12 text-center">
          <div class="navy-line"></div>
          <h1>Contactanos</h1>
          <p>
            Tus dudas acerca de nuestros programas y planes serán resueltas de
            forma personalizada.
          </p>
        </div>
      </div>
      <div class="row m-b-lg">
        <div class="col-lg-3 col-lg-offset-3">
          <address>
            <strong><span class="navy">ACCYON Consultoría</span></strong
            ><br />
            Calzada del ejercito #116 A, Fracc. Fray Junipero Serra<br />
            Tepic, Nayarit , CP 63165<br />
            <abbr title="Teléfono">P:</abbr> (311) 372-4835
          </address>
        </div>
        <div class="col-lg-4">
          <p class="text-color">
            Considera enviarnos datos de contacto y las dudas que tengas con
            respecto a nuestros programas y servicios. A la brevedad nuestro
            personal se pondrá en contacto contigo para brindarte la atención
            que necesitas.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 text-center">
          <a href="mailto:direccion@consultoriaenaccyon.com" class="btn btn-primary"
            >Envianos un e-mail</a
          >
          <p class="m-t-sm">
            Siguenos en redes sociales
          </p>
          <ul class="list-inline social-icon">
            <li>
              <a href="https://www.instagram.com/accyonconsultoria/" target="_blank"><i class="fab fa-instagram"></i></a>
            </li>
            <li>
              <a href="https://www.facebook.com/accyonconsultoria/" target="_blank"><i class="fab fa-facebook-f"></i></a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/accyon-consultoria-a197091a9" target="_blank"><i class="fab fa-linkedin-in"></i></a>
            </li>
          </ul>
        </div>
      </div>
      <!-- footer -->
      <Footer />
    </div>
  </section>
</template>

<script>
import Footer from "./Footer.vue";
export default {
  name: "Contact",
  components: {
    Footer,
  },
};
</script>
