function chunkString(str, length) {
    return str.match(new RegExp('.{1,' + length + '}', 'g'));
}

function chunkSentence(sentence, size) {
    let result = [];
    let words = sentence.split(" ");
    let cword = 0;
    let current_line = "";
    let ww = 0;
    for (let w in words) {

        current_line += ` ${words[w]}`;
        cword++;
        ww++;
        if (cword == size || ww == words.length) {
            cword = 0;
            result.push(current_line);
            current_line = "";
        }
    }

    return result;
}

export { chunkString, chunkSentence };