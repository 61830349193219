<template>
  <div class="navbar-wrapper">
    <nav class="navbar navbar-default navbar-fixed-top" role="navigation">
      <div class="container">
        <div class="navbar-header page-scroll">
          <button
            type="button"
            class="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#navbar"
            aria-expanded="false"
            aria-controls="navbar"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" href="/">ACCYON</a>
        </div>
        <div id="navbar" class="navbar-collapse collapse">
          <ul class="nav navbar-nav navbar-right">
            <li><router-link :to="{ name: 'jobs' }">Empleos</router-link></li>
            <li>
              <router-link :to="{ name: 'register' }">Registro</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'panel' }">Administrador</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.navbar-default {
  background-color: #f4f4f4;
  opacity: .7;
}
.navbar-default .nav li a {
  color: #333;
}

.navbar-default .nav li a:hover {
  color: #1ab394;
}
</style>
