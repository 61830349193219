<template>
  <div class="carousel__item" :style="myStyle"></div>
</template>

<script>
// import firebase from "../Firebase";
export default {
  data() {
    return {
      url: null,
    };
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
  },
  computed: {
    myStyle: function() {
      return {
        backgroundImage: `url("${this.url}")`,
      };
    },
  },
  created() {
    this.url = this.job.imagen.thumbnailImageSrc;
  },
};
</script>

<style>
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--accyon-carousel-color-primary);
  color: var(--accyon-carousel-color-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  will-change: transform;
  transition: transform 450ms;
  cursor: pointer;
}

.carousel__item:hover {
  transform: scale(105%);
  transition: 125ms;
}
</style>
