<template>
  <div class="col-sm-3">
    <h2>{{ data.title }}</h2>
    <p class="text__justify">
      {{ data.description }}
    </p>
    <p v-if="data.link.text != ''">
      <a class="navy-link" :href="data.link.path" role="button"
        >{{ data.link.text }} &raquo;</a
      >
    </p>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: function() {
        return {
          title: "",
          description: "",
          link: { path: "#", text: "" },
        };
      },
    },
  },
};
</script>