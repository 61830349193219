<template>
  <div class="accyon__admin--panel">
    <router-view></router-view>
  </div>
</template>
<style >
.accyon__admin--panel * {
  font-size: 14px !important;
}

.accyon__admin-content {
  background-color: #f8f9fa;
  color: #495057;
}
</style>
