<template>
  <section id="features" class="container services">
    <div class="row">
      <UsCard v-for="(card, index) in cards" :key="index" :data="card" />
    </div>
  </section>
</template>

<script>
import UsCard from "../components/UsCard.vue";
export default {
  name: "Us",
  data() {
    return {
      cards: [
        {
          title: "¿Quienes somos?",
          description:
            "Somos una consultoría especializada en cubrir necesidades de RRHH con una atención personalizada, comprometida con la mejora continua, calidad e innovación.",
          link: { path: "#", text: "" },
        },
        {
          title: "Nuestras fortalezas",
          description:
            "Bolsa de trabajo, Asesoría en contratación, capacitación y seguimiento, Coaching sobre STPS, IMSS Y SAT, Atención personalizada, Mejora continua",
          link: { path: "#", text: "" },
        },
        {
          title: "Nuestra presencia",
          description:
            "Tenemos presencia en más de 15 ciudades del país y el extranjero: Tepic, Nuevo Vallarta, Guadalajara, Colima, Guanajuato, Mazatlán, Culiacán, Houston y muchas más.",
          link: { path: "#", text: "" },
        },
        {
          title: "Nuestra bolsa de trabajo",
          description:
            "Contamos con una bolsa de trabajo con diversidad de perfiles; Gerenciales, Administrativos, Ventas, Operativos y Comerciales.",
          link: { path: "#", text: "" },
        },
      ],
    };
  },
  components: {
    UsCard,
  },
};
</script>
