<template>
  <div class="row">
    <div class="col-lg-8 col-lg-offset-2 text-center m-t-lg m-b-lg">
      <p>
        <strong>&copy; 2021 ACCYON Consultoría</strong><br />
        Todos los derechos reservados. El contenido del sitio esta protegido por
        leyes de propiedad intelectual y derechos de autor. Los logotipos y
        marcas comerciales mostradas son propiedad de sus respectivas compañías.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>
