<template>
  <section
    id="testimonials"
    class="navy-section testimonials"
    style="margin-top: 0"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center wow zoomIn">
          <i class="fa fa-bookmark big-icon"></i>
          <h1>
            Rasgos que nos caracterizan
          </h1>
          <div class="testimonials-text">
            <i
              >Nuestra atención personalizada, la calidad y confianza que le
              brinda nuestro equipo, y la lealtad que tendremos a usted y su
              negocio.
            </i>
          </div>
          <small>
            <!-- <strong>12.02.2014 - Andy Smith</strong> -->
          </small>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Caracteristics",
};
</script>

<style></style>
