<template>
  <section id="jobs" class="features">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="navy-line"></div>
          <h1>Bolsa de trabajo</h1>
          <p>
            El empleo que siempre has querido a tan solo un clic.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-10 col-lg-offset-1">
          <Carousel
            class="carousel__jobs"
            :items-to-show="2.5"
            :wrap-around="true"
            v-if="jobs.length != 0"
          >
            <Slide v-for="(job, index) in jobs" :key="index">
              <CarouselItem :job="job" @click="showJob(job.id)" />
            </Slide>

            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import firebase from "../Firebase";
import CarouselItem from "../components/CarouselItem.vue";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "Jobs",
  data() {
    return {
      loading: false,
      jobs: [],
      count: 0,
    };
  },
  computed: {
    progress: function() {
      return (100 / this.count) * this.jobs.length;
    },
  },
  created() {
    this.loading = true;
    var db = firebase.firestore();
    db.collection("jobs")
      .get()
      .then((querySnapshot) => {
        this.count = querySnapshot.size;

        querySnapshot.forEach((doc) => {
          
          var oDoc = doc.data();
          oDoc.id = doc.id;
          this.jobs.push(oDoc);
        });

        this.loading = false;
      });

    
  },
  methods: {
    showJob(id) {
      this.$router.push({ name: "jobsdetail", params: { jobId: id } });
    },
  },
  components: {
    Carousel,
    Slide,
    Navigation,
    CarouselItem,
  },
};
</script>

<style>
:root {
  --accyon-carousel-color-primary: #1b262b;
  --accyon-carousel-color-secondary: #1ab394;
  --accyon-carousel-color-white: #ffffff;
  --accyon-carousel-nav-width: 30px;
}

.carousel__jobs {
  height: 230px;
}
.carousel__slide {
  padding: 10px;
}

.carousel__next,
.carousel__prev {
  background-color: var(--accyon-carousel-color-primary);
  border-width: 2px;
  border-color: var(--accyon-carousel-color-white);
  border-radius: var(--accyon-carousel-nav-width);
  width: var(--accyon-carousel-nav-width);
  height: var(--accyon-carousel-nav-width);
  text-align: center;
  font-size: calc(var(--accyon-carousel-nav-width) * 2 / 3);
  padding: 0;
  color: var(--accyon-carousel-color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: solid;
  cursor: pointer;
}
</style>
