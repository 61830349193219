import { createRouter, createWebHashHistory } from 'vue-router';
// import { createRouter } from 'vue-router';
import Landing from "./pages/Landing.vue";
import LayoutApp from "./pages/Layout.vue";
import Admin from "./pages/Admin.vue";
const routes = [
    {
        path: '/',
        name: 'landing',
        component: Landing
    },
    {

        path: '/app',
        name: 'app',
        redirect: { name: 'jobs' },
        component: LayoutApp,
        children: [
            {
                path: 'jobs/:jobId',
                name: 'jobsdetail',
                component: () => import(/* webpackChunkName: "jobsdetail" */ './pages/LayoutJobsDetail.vue')

            },
            {
                path: 'jobs',
                name: 'jobs',
                component: () => import(/* webpackChunkName: "jobs" */ './pages/LayoutJobs.vue')
            },
            {
                path: 'register',
                name: 'register',
                component: () => import(/* webpackChunkName: "register" */ './pages/LayoutRegister.vue')
            },
            {
                path: 'conditions',
                name: 'privacyagreement',
                component: () => import(/* webpackChunkName: "privacyagreement" */ './pages/LayoutTermsNConditions.vue')
            },
        ]
    },
    {
        path: '/admin',
        name: 'admin',
        component: Admin,
        redirect: { name: 'login' },
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import(/* webpackChunkName: "login" */ './pages/AdminLogin.vue')
            },
            {
                path: 'panel',
                name: 'panel',
                component: () => import(/* webpackChunkName: "panel" */ './pages/AdminPanel.vue'),
                redirect: { name: 'admin-jobs' },
                children: [
                    {
                        path: 'jobs',
                        name: 'admin-jobs',
                        component: () => import(/* webpackChunkName: "admin-jobs" */ './sections/admin/Jobs.vue')
                    },
                    {
                        path: 'images',
                        name: 'admin-images',
                        component: () => import(/* webpackChunkName: "admin-images" */ './sections/admin/Images.vue')
                    }
                ]
            },
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "notfound" */ './pages/404.vue')
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;