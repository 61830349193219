<template>
  <section class="container features">
    <div class="row">
      <div class="col-lg-12 text-center">
        <div class="navy-line"></div>
        <h1>
          Paquetes de contratación<br />
          <span class="navy"> conoce nuestros servicios</span>
        </h1>
        <p>
          Si requieres algo hecho a tu medida ponte en contacto con nosotros.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 text-center wow fadeInLeft">
        <ServicesCard
          v-for="(card, index) in services.left"
          :key="index"
          :data="card"
        />
      </div>
      <div class="col-md-6 text-center  wow zoomIn">
        <img src="img/perspective.png" alt="dashboard" class="img-responsive" />
      </div>
      <div class="col-md-3 text-center wow fadeInRight">
        <ServicesCard
          v-for="(card, index) in services.right"
          :key="index"
          :data="card"
        />
      </div>
    </div>
  </section>
</template>

<script>
import ServicesCard from "../components/ServicesCard.vue";
export default {
  name: "Services",
  data() {
    return {
      services: {
        left: [
          {
            icon: "fa-cubes",
            title: "Evaluación de desempeño",
            description:
              "Un modelo de evaluación del desempeño eficaz y acorde a las necesidades de tu empresa.",
            bottom: false,
          },
          {
            icon: "fa-file-invoice-dollar",
            title: "Estudio Socioeconómico",
            description:
              "Te permitira conocer el entorno económico, social, familiar, cultural y laboral de una persona.",
            bottom: true,
          },
        ],
        right: [
          {
            icon: "fa-brain",
            title: "Evaluaciones Psicométricas",
            description:
              "Guía significativa que ayuda en el proceso de la gestión de talento en tu empresa por medio de métricas de los aspectos psicológicos y emocionales de un empleado.",
            bottom: false,
          },
          {
            icon: "fa-award",
            title: "Atracción de Talento",
            description:
              "Es el proceso donde nos encargaremos de seleccionar a las personas mejor calificadas para laborar en tu organización.",
            bottom: true,
          },
        ],
      },
    };
  },
  components: {
    ServicesCard,
  },
};
</script>

<style></style>
