import { createApp } from 'vue';
import { createStore } from 'vuex'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2';
import PrimeVue from 'primevue/config';

import firebase from "./Firebase";

import 'sweetalert2/dist/sweetalert2.min.css';
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Password from 'primevue/password';
import AutoComplete from 'primevue/autocomplete';
import Calendar from 'primevue/calendar';
import InputNumber from 'primevue/inputnumber';
import Chips from 'primevue/chips';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Menubar from 'primevue/menubar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import BlockUI from 'primevue/blockui';
import FileUpload from 'primevue/fileupload';
import Toolbar from 'primevue/toolbar';
import Galleria from 'primevue/galleria';
import ContextMenu from 'primevue/contextmenu';
import ProgressBar from 'primevue/progressbar';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Skeleton from 'primevue/skeleton';

import router from './router';
import App from './App.vue';

router.beforeEach(function (to, from, next) {
  window.scrollTo(0, 0);
  next();
});

const app = createApp(App);

app.config.warnHandler = () => null;

// Create a new store instance.
const store = createStore({
  state() {
    return {
      images: [],
      jobs: [],
      jobsTotal: 0,
      jobsListed: 0,
      jobActive: null,
      jobModalDisplay: false,
      imagesUploadGoal: 0,
      imagesUploaded: 0,
    }
  },
  getters: {
    progressUploadImages(state) {
      return (100 / state.imagesUploadGoal) * state.imagesUploaded;
    },
    progressListJobs(state) {
      return (100 / state.jobsTotal) * state.jobsListed;
    },
    jobModalTitlePrefix(state) {
      return state.jobActive ? 'Editar' : 'Nueva'
    }
  },
  mutations: {
    saveJob: function (state, payload) {
      state.jobs.push(payload);
    },
    updateJob: function (state, payload) {
      state.jobs.every(function (element, index) {
        if (element.id === payload.id) {
          state.jobs[index] = payload;
          return true;
        }
      });
    },
    saveImage: function (state, payload) {
      state.images.push({
        itemImageSrc: payload.url,
        thumbnailImageSrc: payload.url,
        title: payload.name,
      });
    },
    removeJob: function (state, index) {
      state.jobs.splice(index, 1);
    },
    removeImage: function (state, index) {
      state.images.splice(index, 1);
    },
    clearImages: function (state) {
      state.images = [];
    },
    clearJobs: function (state) {
      state.jobs = [];
    },
    setJobsTotal: function (state, count) {
      state.jobsTotal = count;
    },
    setCounterImages: function (state, count) {
      state.imagesUploaded = 0;
      state.imagesUploadGoal = count;
    },
    setCounterJobs: function (state, count) {
      state.jobsListed = 0;
      state.jobsTotal = count;
    },
    incrementImagesUploaded: function (state) {
      state.imagesUploaded++;
    },
    incrementJobsListed: function (state) {
      state.jobsListed++;
    },
    setCurrentJob: function (state, payload) {
      state.jobActive = payload;
      state.jobModalDisplay = true;
    },
    setJobModalDisplay: function (state, display) {
      state.jobModalDisplay = display
    }
  },
  actions: {
    getJobsFromStore(context, { app }) {
      context.commit('clearJobs');
      var db = firebase.firestore();
      var oDoc;
      db.collection("jobs")
        .get()
        .then((querySnapshot) => {

          context.commit('setJobsTotal', querySnapshot.size)

          querySnapshot.forEach((doc) => {

            oDoc = doc.data();
            oDoc.id = doc.id;
            context.commit('saveJob', oDoc)
            context.commit('incrementJobsListed');
          });
        }).catch((error) => {
          app.$toast.add({
            severity: "error",
            summary: "Algo salió mal",
            detail: error,
          });
        });
    },
    getImagesFromStore(context, { app }) {
      context.commit('clearImages');
      var storage = firebase.storage();
      var storageRef = storage.ref();
      var listJobRef = storageRef.child("jobs");

      // Find all the prefixes and items.
      listJobRef
        .listAll()
        .then(function (res) {
          res.items.forEach(function (itemRef) {
            itemRef.getDownloadURL().then((url) => {
              context.commit('saveImage', {
                url: url,
                name: itemRef.name
              })
            });
          });
        })
        .catch(function (error) {
          app.$toast.add({
            severity: "error",
            summary: "Algo salió mal",
            detail: error,
          });
        });
    },
    storeJob(context, { payload, app }) {
      var db = firebase.firestore();
      var document = { ...payload };
      delete document.id;
      if (payload.id !== null) {
        db.collection("jobs").doc(payload.id).update(document).then(() => {
          context.commit("updateJob", payload);
          app.$toast.add({
            severity: "success",
            summary: "Vacante actualizada",
            detail: "La vacante se actualizo exitosamente",
            life: 3000,
          });
          context.commit('setJobModalDisplay', false);
        }).catch(error => {
          app.$toast.add({
            severity: "error",
            summary: "Algo salió mal",
            detail: error,
          });
        })
      } else {
        db.collection("jobs").add(document).then(docRef => {
          context.commit("saveJob", { ...document, id: docRef.id });
          app.$toast.add({
            severity: "success",
            summary: "Vacante agregada",
            detail: "La vacante se creo exitosamente",
            life: 3000,
          });
          context.commit('setJobModalDisplay', false);
        }).catch(error => {
          app.$toast.add({
            severity: "error",
            summary: "Algo salió mal",
            detail: error,
          });
        })

      }
    },
    storeImage(context, { name, blob, file, app }) {
      // Create a root reference
      var storage = firebase.storage();
      var storageRef = storage.ref();
      var storageJobRef = storageRef.child("jobs");

      var imageRef = storageJobRef.child(name);

      // Create file metadata including the content type
      var metadata = {
        contentType: file.type,
      };

      imageRef.put(blob, metadata).then((snapshot) => {
        snapshot.ref.getDownloadURL().then(function (downloadURL) {

          context.commit('saveImage', { url: downloadURL, name: name });
          context.commit('incrementImagesUploaded');
          app.$toast.add({
            severity: "success",
            summary: "Imagen almacenada",
            detail: `Se almaceno correctamente ${name}`,
            life: 3000,
          });
        });
      });
    },
    deleteJob(context, { uuid, index, app }) {
      var db = firebase.firestore();
      db.collection("jobs").doc(uuid).delete().then(() => {
        context.commit("removeJob", index);
        app.$toast.add({
          severity: "success",
          summary: "Eliminación efectuada",
          detail: "Vacante eliminada",
          life: 3000,
        });
      }).catch((error) => {
        app.$toast.add({
          severity: "error",
          summary: "Algo salió mal",
          detail: error,
        });
      });
    },
    deleteImage(context, { index, app }) {
      var storage = firebase.storage();
      var storageRef = storage.ref();
      var storageJobRef = storageRef.child("jobs");

      storageJobRef
        .child(`${context.state.images[index].title}`)
        .delete()
        .then(function () {
          context.commit('removeImage', index)
          app.$toast.add({
            severity: "success",
            summary: "Eliminación efectuada",
            detail: "Imagen eliminada",
            life: 3000,
          });
        })
        .catch(function (error) {
          app.$toast.add({
            severity: "error",
            summary: "Problemas al eliminar",
            detail: error,
          });
        });
    },
    startCountImages(context, { count }) {
      context.commit('setCounterImages', count)
    },
    startCountJobs(context, { count }) {
      context.commit('setCounterJobs', count)
    },
    selectedJob(context, { job }) {
      context.commit('setCurrentJob', job)
    },
    hideJobModal(context) {
      context.commit('setJobModalDisplay', false);
    }
  }
})

app.use(router);
app.use(store)
app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService);

app.component('Dialog', Dialog);
app.component('InputText', InputText);
app.component('Password', Password);
app.component('Textarea', Textarea);
app.component('AutoComplete', AutoComplete);
app.component('Calendar', Calendar);
app.component('InputNumber', InputNumber);
app.component('Chips', Chips);
app.component('Card', Card);
app.component('Button', Button);
app.component('Menubar', Menubar);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Toolbar', Toolbar);
app.component('Galleria', Galleria);
app.component('ContextMenu', ContextMenu);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Toast', Toast);
app.component('FileUpload', FileUpload);
app.component('BlockUI', BlockUI);
app.component('ProgressBar', ProgressBar);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Skeleton', Skeleton);

app.mount('#app');