<template>
  <section class="comments gray-section" style="margin-top: 0">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="navy-line"></div>
          <h1>Lo que se comenta de nosotros</h1>
          <p>Testimonios de nuestros clientes y referidos.</p>
        </div>
      </div>
      <div class="row features-block">
        <TestimonialCard
          v-for="(data, index) in testimonials"
          :key="index"
          :data="data"
        />
      </div>
    </div>
  </section>
</template>

<script>
import TestimonialCard from "../components/TestimonialCard.vue";
export default {
  name: "Testimonials",
  data() {
    return {
      testimonials: [
        {
          comments:
            "En ACCYON encontré un buen empleo hace 2 años, donde he crecido y he sentido mucha estabilidad.",
          img: "t1.jpg",
          name: "Agenis Carmona",
          company: "D-Rigali",
        },
        {
          comments:
            "Muchas gracias a la empresa ACCYON por los servicios de calidad que nos presta al atender nuestros requerimientos de personal, seguiremos trabajando con ustedes.",
          img: "t3.jpg",
          name: "Support Staff Manager",
          company: "Mexilink Inc.",
        },
        {
          comments:
            "Son tiempos difíciles y es complicado encontrar oportunidades de empleo sin embargo el equipo de ACCYON me apoyó colocándome en este importante grupo empresarial.",
          img: "t2.jpg",
          name: "Flavia González",
          company: "Comoto Honda",
        },
      ],
    };
  },
  components: {
    TestimonialCard,
  },
};
</script>
