<template>
  <div :class="[data.bottom ? 'm-t-lg' : '']">
    <i :class="['fa', data.icon, 'features-icon']"></i>
    <h2>{{ data.title }}</h2>
    <p class="text__justify">
      {{ data.description }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: function() {
        return {
          title: "",
          description: "",
          icon: "",
          bottom: false,
        };
      },
    },
  },
};
</script>
