<template>
  <li
    data-target="#inSlider"
    :data-slide-to="to"
    :class="[active ? 'active' : '']"
  ></li>
</template>

<script>
export default {
  props: {
    to: {
      type: Number,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>
