<template>
  <section class="timeline gray-section gray-section-wom">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="navy-line"></div>
          <h1>Nuestro proceso</h1>
          <p>Reclutamiento con metodologías bien definidas.</p>
        </div>
      </div>
      <div class="row features-block">
        <div class="col-lg-12">
          <div
            id="vertical-timeline"
            class="vertical-container light-timeline center-orientation"
          >
            <ProcessCard
              v-for="(data, index) in process"
              :key="index"
              :data="data"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProcessCard from "../components/ProcessCard.vue";
export default {
  name: "Process",
  data() {
    return {
      process: [
        {
          icon: "fa-dot-circle",
          title: "Envía tu solicitud o cv",
          description:
            "Al enviar tu cv/solicitud, te pedimos agregues en asunto la vacante y zona por la cual te estas postulando",
          link: { url: "", text: "" },
          info: { text: "3", highlight: "días" },
        },
        {
          icon: "fa-phone",
          title: "Llamada para confirmar datos ",
          description:
            "Una vez que hayas enviado tú información, empezarías tu proceso de selección. Nos pondremos en contacto contigo para revisar información; personal, laboral y experiencia en el puesto",
          link: { url: "", text: "" },
          info: { text: "3", highlight: "días" },
        },
        {
          icon: "fa-cogs",
          title: "Entrevista ",
          description:
            "Cuando cumples con el perfil que solicitamos, te agendamos una entrevista para que puedas conocernos personalmente, plantearte lo que ofrecemos y los detalles de la vacante en la que estas participando.",
          link: { url: "", text: "" },
          info: { text: "3", highlight: "días" },
        },
        {
          icon: "fa-briefcase",
          title: "Contratación ",
          description:
            "Una vez que hayas sido evaluado se te informará si quedaste seleccionado en nuestra vacante, de no ser así te incluimos a nuestra gran bolsa de trabajo donde estarás recibiendo ofertas con base a tu experiencia y en la cuales podrías ser contratado en cualquier momento.",
          link: { url: "", text: "" },
          info: { text: "Bolsa de ", highlight: "trabajo" },
        },
      ],
    };
  },
  components: {
    ProcessCard,
  },
};
</script>
