<template>
  <section id="expertis" class="features">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="navy-line"></div>
          <h1>Somos profesionales</h1>
          <p>
            Agenda una cita para conocer tus necesidades.
          </p>
        </div>
      </div>
      <div class="row m-b-lg">
        <div class="col-lg-4 col-lg-offset-2">
          <p class="text-color text__justify">
            Si, estas en busca de una oportunidad de empleo asegurate de contar
            con tu hoja de vida actualizada y completa el formulario de
            registro.
          </p>
        </div>
        <div class="col-lg-4">
          <p class="text-color text__justify">
            Si, por otro lado estas buscando talento humano que ayude al
            crecimiento de tu negocio, envianos un e-mail y recibiras atención
            personalizada.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Expertis",
};
</script>
